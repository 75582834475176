export default {
  path: '/onboarding',
  name: 'onboarding',
  redirect: '/onboarding/date-of-birth',
  component: () => import('layouts/BlankLayout'),
  children: [
    // {
    //   path: 'mobile-number',
    //   name: 'onboarding-mobile-number',
    //   component: () => import('pages/onboarding/MobileNo'),
    // },
    {
      path: 'date-of-birth',
      name: 'onboarding-date-of-birth',
      component: () => import('pages/onboarding/DateOfBirth'),
    },
    {
      path: 'sex',
      name: 'onboarding-sex',
      component: () => import('pages/onboarding/Sex'),
    },
    {
      path: 'blood-type',
      name: 'onboarding-blood-type',
      component: () => import('pages/onboarding/BloodType'),
    },
    {
      path: 'civil-status',
      name: 'onboarding-civil-status',
      component: () => import('pages/onboarding/CivilStatus'),
    },
    {
      path: 'insurance',
      name: 'onboarding-insurance',
      component: () => import('pages/onboarding/Insurance'),
    },
    {
      path: 'welcome',
      name: 'onboarding-welcome',
      component: () => import('pages/onboarding/Welcome'),
    },
  ],
  meta: {
    requiresAuth: true,
    onboarding: true,
  },
};
