export function setSelectedDoctor (state, data) {
  state.selectedDoctor = data;
}

export function setSelectedDoctorSchedules (state, data) {
  state.selectedDoctorSchedules = data;
}

export function setSelectedDoctorClinics (state, data) {
  state.selectedDoctorClinics = data;
}
