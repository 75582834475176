export default {
  path: '/create-appointment',
  name: 'create-appointment',
  redirect: '/create-appointment/step-1',
  component: () => import('layouts/AppointmentLayout'),
  children: [
    {
      path: 'step-1',
      name: 'create-appointment-step-1',
      component: () => import('pages/appointment/Step1'),
      meta: {
        step: 'step-1',
      },
    },
    {
      path: 'step-2',
      name: 'create-appointment-step-2',
      component: () => import('pages/appointment/Step2'),
      meta: {
        step: 'step-2',
      },
    },
    {
      path: 'step-3',
      name: 'create-appointment-step-3',
      component: () => import('pages/appointment/Step3'),
      meta: {
        step: 'step-3',
      },
    },
    {
      path: 'step-4',
      name: 'create-appointment-step-4',
      component: () => import('pages/appointment/Step4'),
      meta: {
        step: 'step-4',
      },
    },
    {
      path: 'step-5',
      name: 'create-appointment-step-5',
      component: () => import('pages/appointment/Step5'),
      meta: {
        step: 'step-5',
      },
    },
  ],
};
