export default {
  path: '/diagnostic',
  name: 'diagnostic',
  component: () => import('pages/diagnostic'),
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '/diagnostic/lab-order/:id',
      name: 'diagnostic-lab-order',
      component: () => import('pages/diagnostic/LabOrder'),
      props: route => ({
        id: route.params?.id,
      }),
      meta: {
        requiresAuth: true,
        showToolbar: true,
        toolbarTitle: 'Laboratory Order',
        backRoute: 'lab-orders',
      },
    },
    {
      path: '/diagnostic/imaging-order/:id',
      name: 'diagnostic-imaging-order',
      component: () => import('pages/diagnostic/ImagingOrder'),
      props: route => ({
        id: route.params?.id,
      }),
      meta: {
        requiresAuth: true,
        showToolbar: true,
        toolbarTitle: 'Imaging Order',
        backRoute: 'imaging-orders',
      },
    },
  ],
};
