import { ClientService, decodeJWT, TimeoutError, isBrowserNetworkError } from '../../utils';

export const STRATEGY_JWT = 'jwt';
export const STRATEGY_LOCAL = 'local';

export const STORAGE_KEY_JWT = 'mc:jwt';
export const STORAGE_KEY_REF = 'mc:ref';

export const EVENT_CURRENT_USER = 'currentUser';

/**
 * @typedef {Object} SigninPayload
 * @property {string} email
 * @property {string} password
 * @property {string} totpToken
 * @property {string} accessToken
 */

/**
 * @typedef {Object} AccountCredentials
 * @property {string} uid
 * @property {string} accessToken
 * @property {string} refreshToken
 * @property {number} expiresAt access token expiration
 */

/**
 * @typedef {Object} MFAConfig
 * @property {string} uid
 * @property {boolean} enabled
 * @property {?string} dataURL data url to be put in a <img></img> for QR code
 * @property {?string} secret mfa secret
 */

export class AuthenticationService extends ClientService {
  constructor () {
    super('authentication');
  }

  /**
   * setup a client service
   * @param {Client} client
   * @param {string} path
   */
  setup (client, path) {
    super.setup(client, path);

    // signin cached user
    if (this.cachedToken && this.opts.autoSignin) {
      this.signin('jwt', { accessToken: this.cachedToken });
    }

    // emit null on non-cached user
    if (!this.cachedToken && this.opts.emitNullImmediately) {
      this.onCurrentUserChange(null);
    }
  }

  /**
   * @returns {string}
   */
  get cachedToken () {
    return this.client?._storage.getItem(STORAGE_KEY_JWT);
  }

  /**
   * @returns {string}
   */
  get cachedRefreshToken () {
    return this.client?._storage.getItem(STORAGE_KEY_REF);
  }

  /**
   * @param {Account} currentUser
   * @returns {void}
   */
  onCurrentUserChange (currentUser) {
    // no change
    if (this._currentUser === currentUser) return;
    this._currentUser = currentUser || null;

    // clear store on signout
    if (!this._currentUser) {
      this.client._storage.removeItem(STORAGE_KEY_JWT);
      this.client._storage.removeItem(STORAGE_KEY_REF);
    }

    // unsubscribe previous currentUser watcher
    if (typeof this._currentUserUnsub === 'function') {
      this._currentUserUnsub();
      this._currentUserUnsub = null;
    }

    // emit change
    this.emit(EVENT_CURRENT_USER, this._currentUser);
  }

  /**
   * @param {Object} account account to create
   * @returns {Promise<AccountCredentials>}
   */
  async register (account) {
    await this.request('post', null, account, null, 'accounts');
    return this.signin(STRATEGY_LOCAL, account);
  }

  /**
   * @param {string} strategy local
   * @param {SigninPayload} payload
   * @param {boolean} setCurrentUser=true
   * @returns {Promise<Account|AccountCredentials>}
   */
  async signin (strategy, payload, setCurrentUser = true) {
    const data = { strategy: strategy || 'local', ...payload };
    try {
      const creds = await this.request('post', null, data, { accessToken: payload?.accessToken });

      // handle errors
      if (!creds.uid) {
        const error = new Error(creds.message || 'Invalid uid');
        error.code = creds.code;
        error.data = creds.data;
        throw error;
      }

      // store access and refresh tokens in localstorage
      if (setCurrentUser) {
        this.client._storage.setItem(STORAGE_KEY_JWT, creds.accessToken);
        this.client._storage.setItem(STORAGE_KEY_REF, creds.refreshToken);
      }

      // populate user details
      const currentUser = await this
        .request('get', creds.uid, null, { accessToken: creds.accessToken }, 'accounts')
        .then(res => (!res || setCurrentUser) ? res : { ...res, accessToken: creds.accessToken });

      // set current user
      if (setCurrentUser) {
        this.onCurrentUserChange(currentUser || null);
      }

      return currentUser;
    } catch (error) {
      if (setCurrentUser) {
        // avoid signing out on network errors. simply let the user retry
        const isNetworkError = error instanceof TimeoutError || isBrowserNetworkError(error);
        if (!isNetworkError) this.signout();
      }
      throw error;
    }
  }

  /**
   * @param {string} identityKey email,phone???
   * @param {string} identity
   * @returns {Promise<boolean>}
   */
  async checkUniqueIdentity (identityKey, identity) {
    return this.request('post', null, {
      action: 'checkUniqueIdentity',
      identityKey,
      identity,
    }).then(res => !!(res && res.unique));
  }

  /**
   * @param {string} email
   * @returns {Promise<void>}
   */
  async sendPasswordResetEmail (email) {
    return this.request('post', null, {
      action: 'sendPasswordResetEmail',
      email,
    });
  }

  /**
   * Apply an action code
   * @param {string} code
   * @param {Any} [payload]
   * @returns {Promise<void>}
   */
  async applyActionCode (code, payload) {
    return this.request('post', null, {
      action: 'applyActionCode',
      code,
      payload,
    });
  }

  /** ------ REQUIRES and ACTS ON CURRENT USER ------ */

  /**
   * @type {Promise<Account|null>}
   */
  async currentUser (forceReload) {
    if (!forceReload && this._currentUser) return this._currentUser;
    if (!this.cachedToken) return null;
    if (!this?.client.initialized) {
      return null;
    }
    return this.signin('jwt', { accessToken: this.cachedToken });
  }

  /**
   * @returns {Promise<void>}
   */
  async signout () {
    this.onCurrentUserChange(null);
  }

  /**
   * @param {SigninPayload} payload
   * @returns {Promise<void>}
   */
  async deactivate (payload) {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    await this.signin('local', { ...payload, email: user.email });
    await this.request('delete', user.uid, null, null, 'accounts');
  }

  /**
   * @returns {Promise<string>}
   */
  async getAccessToken () {
    const token = this.cachedToken;
    if (token && ((Date.now() / 1000) > decodeJWT(token).exp)) {
      await this.refreshAccessToken();
    }
    return this.cachedToken;
  }

  /**
   * @returns {Promise<void>}
   */
  async refreshAccessToken () {
    try {
      const { accessToken, refreshToken } = await this.request('post', null, {
        action: 'refreshAccessToken',
        refreshToken: this.cachedRefreshToken,
      }, { accessToken: 'fake' });
      this.client._storage.setItem(STORAGE_KEY_JWT, accessToken);
      this.client._storage.setItem(STORAGE_KEY_REF, refreshToken);
    } catch (error) {
      // avoid signing out on network errors. simply let the user retry
      const isNetworkError = error instanceof TimeoutError || isBrowserNetworkError(error);
      if (!isNetworkError) return this.signout();

      // throw error if not signed out
      throw error;
    }
  }

  /**
   * refetches the current user (if exist)'s information
   * @type {Promise<Account|null>}
   */
  async reloadCurrentUser () {
    const user = await this.currentUser(true);
    if (!user) throw new Error('No logged in user');
    const account = await this.request('get', user.uid, null, null, 'accounts');
    if (!account) return this.signout();
    this.onCurrentUserChange(account);
  }

  /**
   * @returns {Promise<void>}
   */
  async sendVerificationEmail () {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    return this.request('post', null, {
      action: 'sendVerificationEmail',
    });
  }

  /**
   * @returns {Promise<void>}
   */
  async sendVerificationSms () {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    return this.request('post', null, {
      action: 'sendVerificationSms',
    });
  }

  /**
   * @param {SigninPayload} payload
   * @param {string} newPassword
   * @returns {Promise<void>}
   */
  async changePassword (payload, newPassword) {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    await this.signin('local', { ...payload, email: user.email });
    return this.request('post', null, {
      action: 'changePassword',
      password: newPassword,
    });
  }

  /**
   * @param {SigninPayload} payload
   * @param {string} identityKey email,phone???
   * @param {string} newIdentity new email or new phone
   * @returns {Promise<void>}
   */
  async changeIdentity (payload, identityKey, newIdentity) {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    await this.signin('local', { ...payload, email: user.email });
    return this.request('post', null, {
      action: 'changeIdentity',
      identityKey,
      identity: newIdentity,
    });
  }

  /**
   * Setup mfa of the current account
   * @param {boolean} enable enable or disable mfa
   * @param {Object} [payload]
   * @param {string} payload.mobileNo send tokens to this mobile no
   * @returns {Promise<MFAConfig>}
   */
  async setupMFA (enable, payload) {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    return this.request('post', null, {
      action: 'setupMFA',
      enable,
      ...payload,
    });
  }

  /**
   * Verify the mfa secret
   * @param {string} token totp token
   * @returns {Promise<{ verified: boolean }>}
   */
  async verifyMFASecret (token) {
    const user = await this.currentUser();
    if (!user) throw new Error('No logged in user');
    return this.request('post', null, {
      action: 'verifyMFASecret',
      totpToken: token,
    });
  }
}
