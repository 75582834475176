export default {
  path: '/directory',
  name: 'directory',
  component: () => import('layouts/DirectoryLayout'),
  redirect: '/directory/search',
  meta: {
    searchBar: true,
  },
  children: [
    {
      path: 'search',
      name: 'directory-search',
      component: () => import('pages/directory/Search'),
      meta: {
        backRoute: 'home',
      },
    },
  ],
};
