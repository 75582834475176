export default function () {
  return {
    /**
     * @property {number} date - Appointment date
     * @property {string} timeSlot.id - Appointment slot id
     * @property {number} timeSlot.startAt - Appointment start time
     * @property {number} timeSlot.endAt - Appointment end time
     * @property {Service[]} services - Array of appointment selected services
     * @property {string} chiefComplaint.type - Record type: 'chief-complaint'
     * @property {string} chiefComplaint.text - The reason | arbitrary field
     * @property {string} chiefComplaint.requestingPhysician - Requesting physician | arbitrary field
     * @property {string} attachmentRaw.type - Record type: 'attachment'
     * @property {string} attachmentRaw.title - Title | arbitrary field
     * @property {string[]} attachmentRaw.attachmentURLs - Array of uploaded attachment urls
     * @property {string} attachment.type - Record type: 'attachment'
     * @property {string} attachment.title - Title | arbitrary field
     * @property {string[]} attachment.attachmentURLs - Array of uploaded attachment urls
     */
    appointmentClinicData: {
      services: [],
      date: null,
      timeSlot: null,
      chiefComplaint: null,
      requestingPhysician: null,
      attachment: {
        title: 'Appointment Attachment',
        attachmentURLs: [],
      },
      attachmentRaw: {
        title: 'Appointment Attachment',
        attachmentURLs: [],
      },
    },
    paymentIntent: {},
  };
}
