import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import appointments from './appointments';
import auth from './auth';
import doctor from './doctor';
import notifications from './notifications';
import personalDetails from './personal-details';

export default function (/* { ssrContext } */) {
  const Store = createStore({
    plugins: [createPersistedState()],
    modules: {
      appointments,
      auth,
      doctor,
      notifications,
      personalDetails,
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING,
  });

  return Store;
}
