export default function () {
  return {
    /**
     * current logged-in user
     * @type {Account}
     **/
    currentUser: null,

    /** @type {AccountConfiguration} */
    currentUserConfiguration: null,
  };
}
