/* global self, globalThis */

import qs from 'qs';
import { isPlainObject, mapValues } from './helpers';

function encodeValues (obj) {
  // array
  if (Array.isArray(obj)) {
    // empty array
    if (!obj.length) return '#[]';
    // iterate list
    return obj.map(encodeValues);
  }

  // boolean
  if (typeof obj === 'boolean') {
    return `#${obj}`;
  }

  // encode numbers
  if (typeof obj === 'number') {
    return `#${obj}`;
  }

  // deep encoding
  if (isPlainObject(obj)) {
    return mapValues(obj, encodeValues);
  }

  // no encoding
  return obj;
}

const HTTP_VERBS = ['head', 'get', 'post', 'put', 'patch', 'delete'];

/**
 * get a property from the global context
 * @param {string} property
 */
function getGlobal (property) {
  if (typeof self !== 'undefined' && self && property in self) return self[property];
  if (typeof window !== 'undefined' && window && property in window) return window[property];
  if (typeof global !== 'undefined' && global && property in global) return global[property];
  if (typeof globalThis !== 'undefined' && globalThis) return globalThis[property];
}

export class HTTPError extends Error {
  constructor (body, response) {
    super((body && body.message) || response.statusText);
    this.name = 'HTTPError';
    this.response = response;
    this.status = response.status;
    if (body) {
      this.name = body.name;
      this.code = body.code;
    }
  }
}

export class TimeoutError extends Error {
  constructor () {
    super('Request timed out');
    this.name = 'TimeoutError';
  }
}

/**
 * @typedef {Object} RequestHandlerOpts
 * @property {string} method
 * @property {Object} body
 * @property {Object} headers
 * @property {Object} baseURI
 * @property {number} timeout
 * @property {boolean} skipParseBody
 * @property {boolean} skipResultToJSON
 * @property {string|Object} searchParams
 */

/**
 * @template T
 * @param {string} input
 * @param {RequestHandlerOpts} options
 * @returns {Promise<T>}
 */
export function fetchRequestHandler (input, options) {
  // get fetch method
  const fetch = getGlobal('fetch');
  if (!fetch) throw new Error('Fetch API not available');

  // add defaults
  options = Object.assign({
    method: 'get',
    timeout: 1000 * 60 * 5, // 5 minutes, in milliseconds
    credentials: 'same-origin',
  }, options);

  // normalize method
  if (~HTTP_VERBS.indexOf(options.method)) {
    options.method = options.method.toUpperCase();
  }

  // normalize uri
  options.baseURI = String(options.baseURI || '');
  if (!options.baseURI.endsWith('/')) options.baseURI += '/';
  input = String(input || '');
  if (options.baseURI && input.startsWith('/')) throw new Error('input must not begin with a slash when using baseURI');
  input = options.baseURI + input;

  // add searchParams
  if (options.searchParams) {
    // convert query string (for POJOs and arrays)
    if (typeof options.searchParams !== 'string') {
      // options.searchParams = new URLSearchParams(options.searchParams).toString();
      options.searchParamsObject = options.searchParams;
      options.searchParamsObjectEncoded = encodeValues(options.searchParams);
      // TODO: implement qs.stringify to make the package truly 0-dependencies
      options.searchParams = qs.stringify(options.searchParamsObjectEncoded, {
        strictNullHandling: true,
      });
    }
    input += `?${options.searchParams}`;
  }

  // addd headers
  options.headers = options.headers || {};
  options.headers['Content-Type'] = 'application/json';

  // normalize body (for POJOs and arrays)
  if (typeof options.body !== 'string') {
    options.bodyObject = options.body;
    options.body = JSON.stringify(options.body);
  }

  // build opts
  const fetchopts = {
    body: options.body,
    method: options.method,
    headers: options.headers,
    credentials: options.credentials,
  };
  const reqObj = {
    url: input,
    bodyObject: options.bodyObject,
    searchParamsObject: options.searchParamsObject,
    searchParamsObjectEncoded: options.searchParamsObjectEncoded,
    ...fetchopts,
  };

  return Promise
    .race([
      // execute REST call
      fetch(input, fetchopts),
      // race a timeout
      new Promise((resolve, reject) => setTimeout(() => reject(new TimeoutError()), options.timeout)),
    ])
    // parse result
    .then(response => options.skipParseBody ? response : response.text().then(body => {
      try {
        body = options.skipResultToJSON ? body : body === '' ? null : JSON.parse(body);
      } catch {
        const error = new Error('Error parsing result');
        error.raw = body;
        throw error;
      }
      if (response.status < 300) return body;
      throw new HTTPError(body, response);
    }));
}
