export function selectedDoctorTelehealthClinic (state) {
  const clinics = state.selectedDoctorClinics;
  return clinics?.find((clinic) => {
    return clinic?.tags?.includes('telehealth');
  });
}

export function selectedDoctorTelehealthClinicSchedules (state, getters) {
  const schedules = getters.selectedDoctorClinics?.doctorSchedules;
  return schedules;
}
