export default {
  path: '/dashboard',
  name: 'dashboard',
  redirect: '/dashboard/home',
  component: () => import('layouts/MainLayout'),
  meta: {
    requiresAuth: true,
    dashboard: true,
  },
  children: [
    {
      path: 'home',
      name: 'home',
      component: () => import('pages/dashboard/home'),
      meta: {
        requiresAuth: true,
        hideFooter: true,
        title: 'Home',
      },
    },
    {
      path: 'appointments',
      name: 'appointments',
      redirect: '/dashboard/appointments/today',
      component: () => import('pages/dashboard/appointments'),
      meta: {
        requiresAuth: true,
        title: 'Booking',
      },
      children: [
        {
          path: 'today',
          name: 'appointments-today',
          props: r => ({ type: 'today' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Today',
          },
        },
        {
          path: 'upcoming',
          name: 'appointments-upcoming',
          props: r => ({ type: 'upcoming' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Upcoming',
          },
        },
        {
          path: 'pending',
          name: 'appointments-pending',
          props: r => ({ type: 'pending' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Pending',
          },
        },
        {
          path: 'accepted',
          name: 'appointments-accepted',
          props: r => ({ type: 'accepted' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Accepted',
          },
        },
        {
          path: 'cancelled',
          name: 'appointments-cancelled',
          props: r => ({ type: 'cancelled' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Cancelled',
          },
        },
        {
          path: 'rejected',
          name: 'appointments-rejected',
          props: r => ({ type: 'rejected' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Rejected',
          },
        },
        {
          path: 'completed',
          name: 'appointments-completed',
          props: r => ({ type: 'completed' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Completed',
          },
        },
        {
          path: 'past',
          name: 'appointments-past',
          props: r => ({ type: 'past' }),
          component: () => import('pages/dashboard/appointments/ListPage'),
          meta: {
            title: 'Booking - Past',
          },
        },
      ],
    },
    {
      path: 'appointments/:id/details',
      name: 'appointment-details',
      props: r => ({
        itemId: r.params.id,
      }),
      component: () => import('pages/appointment/DetailsPage'),
    },
    {
      path: 'queue-monitor',
      name: 'queue-monitor',
      component: () => import('pages/dashboard/queue-monitor'),
      meta: {
        requiresAuth: true,
        hideFooter: true,
        title: 'Queue Monitor',
      },
    },
    {
      path: 'medical-records',
      name: 'medical-records',
      redirect: '/dashboard/medical-records/clinic-records',
      component: () => import('pages/dashboard/medical-records'),
      meta: {
        title: 'Medical Records',
      },
      children: [
        {
          path: 'clinic-records',
          name: 'clinic-records',
          component: () => import('pages/dashboard/medical-records/ClinicRecords'),
          meta: {
            title: 'Clinic Records',
          },
        },
        {
          path: 'medical-diary',
          name: 'medical-diary',
          component: () => import('pages/dashboard/medical-records/Diary'),
          meta: {
            title: 'Medical Diary',
          },
        },
        {
          path: 'my-records',
          name: 'my-records',
          component: () => import('pages/dashboard/medical-records/MyRecords'),
          meta: {
            title: 'My Records',
          },
        },
        {
          path: 'vitals',
          name: 'vitals',
          component: () => import('pages/dashboard/medical-records/Vitals'),
          meta: {
            title: 'Vitals',
          },
        },
      ],
    },
    {
      path: 'dx',
      name: 'dx',
      redirect: { name: 'dx-lab-orders' },
      component: () => import('pages/dashboard/diagnostic'),
      meta: {
        title: 'Diagnostic Records',
      },
      children: [
        {
          path: 'lab-orders',
          name: 'dx-lab-orders',
          component: () => import('pages/dashboard/diagnostic/LabOrders'),
          meta: {
            title: 'Laboratory Orders',
          },
        },
        {
          path: 'imaging-orders',
          name: 'dx-imaging-orders',
          component: () => import('pages/dashboard/diagnostic/ImagingOrders'),
          meta: {
            title: 'Imaging Orders',
          },
        },
      ],
    },
    {
      path: 'pme-records',
      name: 'pme-records',
      component: () => import('pages/dashboard/pme-records'),
      meta: {
        title: 'PME Records',
      },
    },
    {
      path: 'transactions',
      name: 'transactions',
      redirect: '/dashboard/transactions/invoices',
      component: () => import('pages/dashboard/transactions'),
      meta: {
        title: 'Transactions',
      },
      children: [
        {
          path: 'invoices',
          name: 'transactions-invoices',
          component: () => import('pages/dashboard/transactions/Invoices'),
          meta: {
            title: 'Invoices',
          },
        },
      ],
    },
  ],
};
