import EventEmitter from 'eventemitter2';

export const EVENT_UPLOADING = 'uploading';
export const EVENT_PROGRESS = 'progress';

/**
 * @typedef {import('../client.js').Client} Client
 */

/**
 * @typedef {Object} UploadFileOptions
 * @property {string} [service='storage'] storage service
 * @property {string} ext
 * @property {string} filename
 */

/**
 * @typedef {Object} UploadedObject
 * @property {string} uri data uri
 * @property {string} id
 * @property {number} size
 * @property {string} contentType
 */

export class ObservableUploadTask extends EventEmitter {
  /**
   * @param {string} file data url [base64]
   * @property {Client} client
   * @param {UploadFileOptions} [opts]
   */
  constructor (file, client, opts) {
    super();
    this.file = file;
    this.client = client;
    this.opts = Object.assign({
      service: 'storage',
    }, opts);

    if (!this.file) throw new Error('No file to upload');
    if (!this.client) throw new Error('client required');
  }

  /**
   * start upload
   * @param {Object} opts
   * @param {boolean} opts.includeMeta
   * @returns {Promise<string|UploadedObject>} download uri
   */
  async upload (opts) {
    const service = this.client.service(this.opts.service);

    // mark upload start
    this.emit(EVENT_UPLOADING, true);
    this.emit(EVENT_PROGRESS, 0);

    // TODO: implement progress watcher

    const result = await service.request('post', null, {
      uri: this.file,
      // TODO: add metadata
    }, null, undefined, { timeout: 300000 });

    this.emit(EVENT_PROGRESS, 100);

    // return only the uri
    return opts?.includeMeta ? result : result?.uri;
  }

  pause () {
    // TODO: implement
  }

  resume () {
    // TODO: implement
  }

  cancel () {
    // TODO: implement
  }
}
