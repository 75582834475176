export function setAppointmentServices (state, services) {
  state.appointmentClinicData.services = services;
}

export function setAppointmentDate (state, date) {
  state.appointmentClinicData.date = date;
}

export function setAppointmentTime (state, { selectedDate, timeSlot }) {
  const startDate = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
  const endDate = new Date(startDate);

  const startTime = new Date(timeSlot?.startTime);
  startDate.setHours(startTime.getHours());
  startDate.setMinutes(startTime.getMinutes());

  const endTime = new Date(timeSlot?.endTime);
  endDate.setHours(endTime.getHours());
  endDate.setMinutes(endTime.getMinutes());

  state.appointmentClinicData.timeSlot = {
    ...timeSlot,
    startTime: startDate.getTime(),
    endTime: endDate.getTime(),
  };
}

export function setAppointmentChiefComplaint (state, chiefComplaint) {
  state.appointmentClinicData.chiefComplaint = chiefComplaint;
}

export function setAppointmentRequestingPhysician (state, requestingPhysician) {
  state.appointmentClinicData.requestingPhysician = requestingPhysician;
}

export function setAppointmentAttachment (state, urls) {
  state.appointmentClinicData.attachment.attachmentURLs = urls;
}

export function setAppointmentAttachmentRaw (state, urls) {
  state.appointmentClinicData.attachmentRaw.attachmentURLs = urls;
}

export function setPaymentIntent (state, paymentIntent) {
  state.paymentIntent = paymentIntent;
}

export function clearAppointmentData (state) {
  state.appointmentClinicData = {
    services: [],
    date: null,
    timeSlot: null,
    chiefComplaint: null,
    requestingPhysician: null,
    attachment: {
      title: 'Appointment Attachment',
      attachmentURLs: [],
    },
    attachmentRaw: {
      title: 'Appointment Attachment',
      attachmentURLs: [],
    },
  };
  state.paymentIntent = {};
}
