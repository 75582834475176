import sdk from '@mycure/sdk-js';

export async function loadCurrentUser (ctx, opts) {
  opts = Object.assign({}, opts);

  // check current state
  if (ctx.state.currentUser && !opts.$forceFetch) return ctx.state.currentUser;

  // fetch from sdk
  const currentUser = await sdk.service('auth').currentUser(true);

  ctx.commit('setCurrentUser', currentUser);

  // return current user
  return currentUser;
}

export async function signin (ctx, opts) {
  opts = Object.assign({}, opts);

  // opts
  const { strategy, setCurrentUser = true, ...payload } = opts;

  // reset store
  if (setCurrentUser) ctx.commit('setCurrentUser', null);

  // signin
  const user = await sdk.service('auth').signin(strategy, payload, setCurrentUser);

  // return signin result (signin credentials)
  if (!setCurrentUser) return user;

  // load current user to store
  return ctx.dispatch('loadCurrentUser', { $forceFetch: true });
}

export async function signout (ctx, opts) {
  // signout
  await sdk.service('auth').signout();
  ctx.commit('setCurrentUser', null);
}

export async function getAccessToken (ctx, opts) {
  // check current user
  if (!ctx.state.currentUser) throw new Error('No logged in user');

  // execute
  return sdk.service('auth').getAccessToken();
}

export async function loadCurrentUserConfiguration (ctx, opts) {
  opts = Object.assign({ }, opts);

  if (!ctx.state.currentUser) throw new Error('Current user must be set before loading configuration');
  if (!opts.configurations?.length) throw new Error('Configuration keys or fields required');

  if (!opts.$forceFetch && ctx.state.currentUserConfiguration) return ctx.state.currentUserConfiguration;

  const configuration = await sdk.service('account-configurations').get(ctx.state.currentUser.uid);
  ctx.commit('setCurrentUserConfiguration', configuration || { uid: ctx.state.currentUser.uid });

  return ctx.state.currentUserConfiguration;
}

export async function updateCurrentUserConfiguration (ctx, opts) {
  opts = Object.assign({ }, opts);

  if (!ctx.state.currentUser) throw new Error('Current user must be set before loading configuration');
  if (!opts.data || !Object.keys(opts.data).length) throw new Error('Update data required');

  const configuration = await sdk.service('account-configurations').update(ctx.state.currentUser.uid, opts.data);
  if (!configuration) {
    throw new Error('Configuration not found');
  }

  ctx.commit('setCurrentUserConfiguration', configuration);

  return ctx.state.currentUserConfiguration;
}
