import { route } from 'quasar/wrappers';
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router';
import routes from './routes';
import guard from './guard';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory);

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE),
  });

  // redirect unexpected errors to unexpected page
  // Router.onError((error, to, from) => {
  //   console.warn('[NAV error]', error, to, from);
  //   const routeconf = {
  //     name: 'unexpected',
  //     query: {
  //       reason: error.message,
  //     },
  //   };
  //   if (to.name !== from.name) {
  //     routeconf.query.next = to.fullPath;
  //   }
  //   if (from.name !== 'unexpected') {
  //     routeconf.query.from = from.fullPath;
  //   }
  //   Router.replace(routeconf);
  // });

  Router.beforeEach(guard);

  return Router;
});
