export default {
  path: '/',
  name: 'landing',
  component: () => import('layouts/Standard'),
  redirect: '/signin',
  children: [
    {
      name: 'signin',
      path: 'signin',
      // component: () => import('pages/SigninEmail'),
      component: () => import('pages/Signin'),
    },
    {
      name: 'signin-email',
      path: 'signin-email',
      props: r => ({
        redirect: r.query.redirect,
      }),
      component: () => import('pages/SigninEmail'),
      meta: {
        showToolbar: true,
      },
    },
    {
      name: 'signup-email',
      path: 'signup-email',
      component: () => import('pages/SignupEmail'),
      meta: {
        showToolbar: true,
        toolbarTitle: 'Create a new account',
      },
    },
    {
      name: 'forgot-password',
      path: 'forgot-password',
      component: () => import('pages/ForgotPassword'),
      meta: {
        showToolbar: true,
        toolbarTitle: 'Forgot password',
      },
    },
  ],
};
