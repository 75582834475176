import sdk from '@mycure/sdk-js';
import _ from 'lodash';

export async function loadPersonalDetails (ctx, opts) {
  opts = Object.assign({}, opts);

  // fetch from sdk
  const personalDetails = await sdk.service('personal-details').get(opts.uid);

  ctx.commit('setPersonalDetails', personalDetails);

  // return current user
  return personalDetails;
}

export async function updatePersonalDetails (ctx, opts) {
  const payload = _.omit(opts, ['uid']);
  await sdk.service('personal-details').update(opts.uid, payload);
  ctx.dispatch('loadPersonalDetails', { uid: opts.uid });
}

export function clearPersonalDetails (ctx, opts) {
  ctx.commit('setPersonalDetails', null);
}
