/// imports and local declarations
/**
 * @typedef {import('./state').default} State
 * @typedef {import('./state').Account} Account
 * @typedef {import('./state').AccountConfiguration} AccountConfiguration
 */

/**
 * @param {State} state
 * @param {Account} obj
 */
export function setCurrentUser (state, obj) {
  if (state.currentUser && obj && obj.uid !== state.currentUser.uid) {
    throw new Error('Cannot change current user without signing out');
  }

  // set new current user
  state.currentUser = obj || null;

  // reset configuration
  if (!state.currentUser) {
    state.currentUserConfiguration = null;
  }
}

/**
 * @param {State} state
 * @param {AccountConfiguration} obj
 */
export function setCurrentUserConfiguration (state, obj) {
  if (obj && !state.currentUser) {
    throw new Error('Cannot set current user configuration if no current user is set');
  }
  if (obj && obj.uid !== state.currentUser.uid) {
    throw new Error('Configuration is not for the current user');
  }

  // set new current user
  state.currentUserConfiguration = obj || null;
}
