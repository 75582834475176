import sdk from '@mycure/sdk-js';

export async function getDoctorDetails (ctx, opts) {
  opts = Object.assign({}, opts);

  if (!opts.uid) {
    ctx.commit('setSelectedDoctor', null);
    return null;
  }

  // fetch from sdk
  let personalDetails = await sdk.service('personal-details').get(opts.uid);

  /* TODO: Refactor this, this was done to handle the case where you cant fetch
  anything from personal details service. It will derive it from organization-members service
  */
  if (!personalDetails) {
    const member = await sdk.service('organization-members').findOne({
      id: opts.uid,
      $populate: {
        personalDetails: {
          service: 'personal-details',
          method: 'get',
          localKey: 'uid',
          foreignKey: 'uid',
        },
      },
    });
    personalDetails = member?.$populated?.personalDetails;
  }

  ctx.commit('setSelectedDoctor', personalDetails);

  // return current user
  return personalDetails;
}
