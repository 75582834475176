import pkg from '../package.json';
import EventEmitter from 'eventemitter2';
import { ClientService, CRUDClientService, fetchRequestHandler } from './utils';

/**
 * @typedef {import('./utils').ClientService} ClientService
 * @typedef {import('./utils').CRUDClientService} CRUDClientService
 * @typedef {import('./utils').RequestHandlerOpts} RequestHandlerOpts
 */

/**
 * @callback PersistentStorageSetter
 * @param {string} key
 * @param {string | boolean | number} val
 * @returns {string | boolean | number}
 */

/**
 * @callback PersistentStorageGetter
 * @param {string} key
 * @returns {string | boolean | number}
 */

/**
 * @callback PersistentStorageRemover
 * @param {string} key
 * @returns {void}
 */

/**
 * @typedef {Object} PersistentStorage
 * @property {PersistentStorageSetter} setItem
 * @property {PersistentStorageGetter} getItem
 * @property {PersistentStorageRemover} removeItem
 */

/**
 * @template T
 * @callback RequestHandler
 * @param {string} input
 * @param {RequestHandlerOpts} opts
 * @returns {Promise<T>}
 */

/**
 * @typedef {Object} ClientOpts
 * @property {Storage} [storage]
 * @property {RequestHandler<any>} [requestHandler]
 */

export class Client extends EventEmitter {
  /**
   * @param {Object} [opts]
   * @param {Storage} opts.storage
   * @param {RequestHandler<any>} opts.requestHandler
   */
  constructor (opts) {
    super();
    /** @type {Object.<string,any>} */
    this._settings = {};

    /** @type {Object<string,ClientService|CRUDClientService>} */
    this._services = {};
    this.setPersistentStorage(opts?.storage || globalThis.localStorage);
    this.setRequestHandler(opts?.requestHandler || ((...args) => fetchRequestHandler(...args)));
  }

  /**
   * get value from settings
   * @param {string} key
   * @returns {any}
   */
  get (key) {
    return this._settings[key];
  }

  /**
   * set a settings' value
   * @param {string} key
   * @param {any} value
   * @returns {Client}
   */
  set (key, value) {
    this._settings[key] = value;
    return this;
  }

  /**
   * set the client's persistent storage
   * @param {Storage} storage
   * @return {Client}
   */
  setPersistentStorage (storage) {
    if (!storage) return;
    /**
     * persistent storage
     * @type {Storage}
     **/
    this._storage = storage;
    return this;
  }

  /**
   * set the client's persistent storage
   * @param {RequestHandler<any>} storage
   * @return {Client}
   */
  setRequestHandler (handler) {
    if (!handler) return;
    /**
     * HTTPRest request handler
     * @type {RequestHandler<any>}
     **/
    this._requestHandler = handler;
    return this;
  }

  /**
   * initialize client
   * @param {string} uri api server uri
   * @param {Object} [opts]
   */
  initialize (uri, opts) {
    opts = Object.assign({}, opts);

    if (!uri) throw new Error('URI required');

    if (this.uri === uri) {
      console.warn(`Client already initialized with uri: ${uri}`);
      return this;
    }

    // server uri
    this.uri = uri;

    // initialize services
    Object.keys(this._services).forEach(path => this._services[path].setup(this, path));
    this.initialized = true;

    // connect to realtime
    this._enableWs = !!opts.enableWs;
    this.connectWs();
    this.service('auth', true).on('currentUser', () => this.connectWs());

    return this;
  }

  /**
   * Connects to the realtime server using websocket.
   *
   * @returns {Promise<void>}
   */
  async connectWs () {
    if (!this._enableWs) return;

    const token = await this.service('auth', true).getAccessToken?.();

    // no change in token
    if (this.ws && token === this.prevToken) {
      return;
    }
    this.prevToken = token;

    // close existing ws
    const prevWs = this.ws;
    this.ws = null;
    if (prevWs) prevWs.close();

    // connect to realtime
    const wssurl = new URL(this.uri.replace('http', 'ws') + '/ws');
    if (token) wssurl.searchParams.set('token', token);
    const ws = this.ws = new globalThis.WebSocket(wssurl);
    ws._status = 'CONNECTING';

    ws.onopen = () => {
      ws._status = 'OPEN';
    };
    ws.onclose = (ev) => {
      ws._status = 'CLOSED';
      this.ws = null;
      const delay = 1000;
      setTimeout(this.connectWs.bind(this), delay);
    };
    ws.onmessage = (ev) => {
      const message = JSON.parse(ev.data);
      this.emit('ws:message', message);
    };
  }

  /**
   * Register a websocket message handler
   * @param {string} path - path to listen to
   * @param {object} opts - options
   * @param {string} opts.event - event to listen to
   * @param {function} handler - message handler
   * @returns {function} unsubscribe function
   */
  onWsMessage (path, opts, handler) {
    if (!path) throw new Error('path is required');
    if (typeof opts === 'function') {
      handler = opts;
      opts = {};
    }
    if (typeof handler !== 'function') throw new Error('handler is required');
    opts = Object.assign({
      resolveDataOnly: true,
    }, opts);
    const onMessage = message => {
      if (message.path !== path) return;
      if (opts?.event && message.event !== opts.event) return;
      const result = opts.resolveDataOnly ? message.data : message;
      handler(result);
    };
    this.on('ws:message', onMessage);
    return () => this.off('ws:message', onMessage);
  }

  /**
   * register a service
   * @param {string} path
   * @param {ClientService|CRUDClientService} service
   * @returns {ClientService|CRUDClientService}
   */
  use (path, service) {
    if (!(service instanceof ClientService)) {
      throw new Error(`Service '${path}' must be an instance of ClientService`);
    }
    if (this._services[path]) {
      throw new Error(`A service has been already registered to path '${path}'`);
    }

    // register service to map
    service = this._services[path] = service;

    // initial service if added post initialization
    if (this.initialized) {
      service.setup(this, path);
    }

    return service;
  }

  /**
   * get service (create if no exist)
   * @param {string} path
   * @param {boolean} nocreate do not create non-existing service
   * @returns {ClientService|CRUDClientService}
   */
  service (path, nocreate) {
    return this._services[path] || (nocreate ? null : this.use(path, new CRUDClientService(path)));
  }

  /**
   * execute http request
   * @param {string} method
   * @param {string} [id]
   * @param {CreatePayloadItem|CreatePayloadItem[]|UpdatePayload} [data]
   * @param {ClientServiceParams} [params]
   * @param {RequestHandlerOpts} opts request handler override
   * @returns {Promise<ServiceDocument>}
   */
  async request (method, id, data, params, opts) {
    // build request options
    const options = {
      method,
      headers: {
        'X-SDK-Version': `${pkg.name}/${pkg.version}`,
      },
      baseURI: this.uri || '',
      ...opts,
    };

    // attach body
    if (data) {
      options.body = data;
    }

    // build query string
    if (params?.query) {
      options.searchParams = params.query;
    }

    // attach access token
    const accessToken = params?.accessToken || await this.service('auth', true)?.getAccessToken?.();
    if (accessToken) {
      options.headers.Authorization = `Bearer ${accessToken}`;
    }

    // execute request
    return this._requestHandler(id || '', options);
  }
}

// initialize a default client
export default new Client();
