import { CRUDClientService } from '../../utils';

export const EVENT_TOKEN = 'token';

export class NotificationsService extends CRUDClientService {
  constructor () {
    super('notifications');
  }

  /**
   * Uses the given VAPID (Voluntary Application Server Identification) key
   * @param {string} key
   * @returns {void}
   */
  setPublicVAPIDKey (key) {
    // TODO: listen to token changes and emit new token if changed
    // this.emit(EVENT_TOKEN, 'token')
  }
}
