import { ClientService } from './ClientService';
import { isPlainObject, mapValues, cloneDeep, pickBy } from './helpers';

export function cleanData (data) {
  if (Array.isArray(data)) {
    return data.map(cleanData).filter(d => d && (!isPlainObject(d) || Object.keys(d).length));
  }
  if (isPlainObject(data)) {
    return pickBy(mapValues(data, cleanData), v => v != null && v !== '' && (!isPlainObject(v) || Object.keys(v).length));
  }
  if (typeof data === 'string') {
    return data.trim();
  }
  return data;
}

export function cleanUpdates (data, opts) {
  data = cloneDeep(data);
  opts = Object.assign({
    unsettables: [],
  }, opts);
  const $unset = {};
  const looper = (parent, parentKeys, key, obj) => {
    const currentPaths = parentKeys.concat(key).filter(Boolean);
    const currentPath = currentPaths.join('.');
    if (obj == null || obj === '') {
      if (!parent || !key) return;
      if (~opts.unsettables.indexOf(currentPath)) {
        $unset[currentPath] = 1;
      }
      delete parent[key];
      return;
    }
    if (!isPlainObject(obj)) return;
    Object.keys(obj).forEach(k => looper(obj, currentPaths, k, obj[k]));

    // empty object post cleanup
    if (!Object.keys(obj).length) {
      if (~opts.unsettables.indexOf(currentPath)) {
        $unset[currentPath] = 1;

        // remove child unsets
        const regexp = new RegExp(`^${currentPath}.`);
        Object.keys($unset).forEach(key => {
          if (!regexp.test(key)) return;
          delete $unset[key];
        });
      }
      if (isPlainObject(parent)) {
        delete parent[key];
      }
    }
  };
  looper(null, [], '', data);
  if (Object.keys($unset).length) data.$unset = $unset;
  return data;
}

export function formatQuery (query, params) {
  query = query || {};
  if (params && params.limit) query.$limit = params.limit;
  if (params && params.skip) query.$skip = params.skip;
  if (params && params.sort) query.$sort = params.sort;
  if (params && params.select) query.$select = params.select;
  if (params && params.project) query.$project = params.project;
  if (params && params.query) Object.assign(query, params.query);
  return query;
}

export class CRUDClientService extends ClientService {
  find (query, params) {
    const execute = () => this.request('get', null, null, { ...params, query: formatQuery(query, params) })
      .then(res =>
        params?.returnRawResponse
          ? res
          : ({
              items: Array.isArray(res) ? res : res?.data || [],
              total: Array.isArray(res) ? undefined : res.total,
              limit: Array.isArray(res) ? undefined : res.limit,
              skip: Array.isArray(res) ? undefined : res.skip,
            }),
      );

    // build promise
    const promise = params?.watchOpts?.emitInitial === false
      ? Promise.resolve()
      : execute();

    promise.watch = (opts = params?.watchOpts, cb, errCb) => {
      // callback only
      if (typeof opts === 'function') {
        errCb = cb;
        cb = opts;
        opts = params?.watchOpts;
      }
      if (typeof cb !== 'function') throw new Error('Invalid callback function');
      opts = Object.assign({
        emitInitial: true,
        matcher: () => true,
      }, opts);

      // internal cache
      const unsubs = [];
      let items = [];

      // handlers
      const createFn = item => {
        if (!opts.matcher(item, 'create', items)) return;
        if (!opts.emitChangedItem) items.push(item);
        else Object.defineProperty(item, '__op', { value: 'create' });
        cb(!opts.emitChangedItem ? items : [item]);
      };
      const updateFn = item => {
        if (!opts.matcher(item, 'update', items)) return;
        if (!opts.emitChangedItem) {
          const index = items.findIndex(i => i[this.opts.id] === item[this.opts.id]);
          if (~index) items[index] = item;
          else items.push(item);
        } else Object.defineProperty(item, '__op', { value: 'update' });
        cb(!opts.emitChangedItem ? items : [item]);
      };
      const removeFn = item => {
        if (!opts.matcher(item, 'remove', items)) return;
        if (!opts.emitChangedItem) items = items.filter(i => i[this.opts.id] !== item[this.opts.id]);
        else Object.defineProperty(item, '__op', { value: 'remove' });
        cb(!opts.emitChangedItem ? items : [item]);
      };

      // cleanup
      const unsub = () => {
        while (unsubs.length) unsubs.pop()();
      };

      Promise
        .resolve(opts.emitInitial ? promise.then() : null)
        .then(async result => {
          // initial items
          if (result) {
            items = result.items;
            if (!opts.emitChangedItem) cb(items);
            else items.forEach(item => cb([item]));
          }

          // register handlers
          unsubs.push(this.client.onWsMessage(this.name, { event: 'created' }, createFn));
          unsubs.push(this.client.onWsMessage(this.name, { event: 'patched' }, updateFn));
          unsubs.push(this.client.onWsMessage(this.name, { event: 'removed' }, removeFn));
        })
        .catch(error => {
          unsub();
          if (typeof errCb === 'function') errCb(error);
        });

      return unsub;
    };
    return promise;
  }

  async count (query, params) {
    query = formatQuery(query, { ...params, limit: 0 });
    return this.request('get', null, null, { ...params, query }).then(res => res.total || 0);
  }

  findOne (query, params) {
    const execute = () => typeof query === 'string'
      ? this.request('get', query, null, params)
      : this.request('get', null, null, { ...params, query: formatQuery(query, { ...params, limit: 1 }) })
        .then(res => Array.isArray(res) ? res : res?.data)
        .then(res => res?.[0] || null);
    const promise = execute();

    promise.watch = (opts = params?.watchOpts, cb, errCb) => {
      if (typeof cb !== 'function') throw new Error('Invalid callback function');

      // internal cache
      const unsubs = [];
      let id = null;

      // handlers
      const updateFn = i => id && i[this.opts.id] === id && cb(i);
      const removeFn = i => id && i[this.opts.id] === id && cb(null);

      // cleanup
      const unsub = () => {
        while (unsubs.length) unsubs.pop()();
      };

      promise
        .then(async item => {
          // initial item
          cb(item);
          if (!item) return;

          // register handers
          id = item[this.opts.id];
          unsubs.push(this.client.onWsMessage(this.name, { event: 'patched' }, updateFn));
          unsubs.push(this.client.onWsMessage(this.name, { event: 'removed' }, removeFn));
        }).catch(error => {
          unsub();
          if (typeof errCb === 'function') errCb(error);
        });

      return unsub;
    };
    return promise;
  }

  get (id, params) {
    return this.findOne(id, params);
  }

  async create (data, params) {
    const orig = data;
    data = cleanData(data);
    return this.request('post', null, data, params);
  }

  async update (query, data, params) {
    const orig = data;
    data = cleanUpdates(data, this.opts);
    return typeof query === 'string'
      ? this.request('patch', query, data, params)
      : this.request('patch', null, data, { ...params, query: formatQuery(query, params) });
  }

  async updateOne (query, data, params) {
    return this.update({ ...query, $limit: 1 }, data, params).then(res => res[0] || null);
  }

  async remove (query, params) {
    return typeof query === 'string'
      ? this.request('delete', query, null, params)
      : this.request('delete', null, null, { ...params, query: formatQuery(query, params) });
  }

  async removeOne (query, params) {
    return this.remove({ ...query, $limit: 1 }, params).then(res => res[0] || null);
  }
}
