import appointmentRoutes from './routes-appointment';
import dashboardRoutes from './routes-dashboard';
import landingRoutes from './routes-landing';
import onboardingRoutes from './routes-onboarding';
import directoryRoutes from './routes-directory';
import diagnosticRoutes from './routes-diagnostic';
import pmeRoutes from './routes-pme';

const routes = [
  { ...appointmentRoutes },
  { ...dashboardRoutes },
  { ...landingRoutes },
  { ...onboardingRoutes },
  { ...directoryRoutes },
  { ...diagnosticRoutes },
  { ...pmeRoutes },
  {
    name: 'create-appointment-success',
    path: '/create-appointment/success',
    component: () => import('pages/appointment/Success'),
  },
  {
    path: '/otp',
    name: 'otp',
    component: () => import('pages/Otp'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('pages/VerifyEmail'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/waiting-room/:id',
    name: 'waiting-room',
    component: () => import('pages/dashboard/appointments/WaitingRoom'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/consultation/:session',
    name: 'consultation-session',
    component: () => import('pages/dashboard/appointments/ConsultationSession'),
    props: route => ({
      sessionId: route.params?.session,
    }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('pages/Profile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/appointment/:id',
    name: 'appointment',
    component: () => import('pages/dashboard/appointments/Preview'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/record-preview/:id',
    name: 'record-preview',
    component: () => import('pages/dashboard/medical-records/Preview'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/clinic-preview/:id',
    name: 'clinic-preview',
    redirect: { name: 'clinic-preview-details' },
    component: () => import('pages/dashboard/directory/ClinicPreview'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'details',
        name: 'clinic-preview-details',
        props: r => ({
          itemId: r.params.id,
        }),
        component: () => import('pages/dashboard/directory/PublishingEntry'),
      },
      {
        path: 'services',
        name: 'clinic-preview-services',
        component: () => import('pages/dashboard/directory/ClinicPreviewServices'),
      },
    ],
  },
  {
    path: '/doctor-preview/:id',
    name: 'doctor-preview',
    redirect: '/doctor-preview/:id/clinics',
    component: () => import('pages/dashboard/directory/DoctorPreview'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'details',
        name: 'doctor-preview-details',
        component: () => import('pages/dashboard/directory/DoctorPreviewDetails'),
      },
      {
        path: 'clinics',
        name: 'doctor-preview-clinics',
        component: () => import('pages/dashboard/directory/DoctorPreviewClinics'),
      },
      {
        path: 'services',
        name: 'doctor-preview-services',
        component: () => import('pages/dashboard/directory/DoctorPreviewServices'),
      },
      {
        path: 'learning-corner',
        name: 'doctor-preview-learning-corner',
        component: () => import('pages/dashboard/directory/DoctorPreviewLearningCorner'),
      },
    ],
  },
  {
    path: '/medical-diary/create',
    name: 'medical-diary-create',
    component: () => import('pages/dashboard/medical-records/DiaryCreate'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my-records/create',
    name: 'my-records-create',
    component: () => import('pages/dashboard/medical-records/MyRecordsCreate'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vitals/create',
    name: 'vitals-create',
    component: () => import('pages/dashboard/medical-records/VitalsCreate'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('pages/Settings'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/print/:id',
    name: 'print',
    component: () => import('pages/Print'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('pages/Notifications'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/doctor-opt-in',
    name: 'doctor-opt-in',
    component: () => import('pages/DoctorOptIn'),
    meta: {
      requiresAuth: true,
      optIn: true,
    },
  },
  {
    path: '/oauth/facebook',
    name: 'oauth-facebook',
    component: () => import('pages/oauth/Facebook'),
  },
  {
    path: '/payment/authorization/success',
    name: 'payment-authorization-success',
    component: () => import('pages/payment/AuthorizationSuccess'),
  },
  {
    path: '/payment/authorization/error',
    name: 'payment-authorization-error',
    component: () => import('pages/payment/AuthorizationError'),
  },
  // {
  //   path: '/jitsi',
  //   name: 'jitsi',
  //   component: () => import('pages/Jitsi'),
  // },
  {
    path: '/iframe-test',
    name: 'iframe-test',
    component: () => import('pages/IFrameTest'),
  },
  {
    path: '/demo-consult/:sessionId',
    name: 'demo-consult',
    component: () => import('pages/demo-consult/DemoConsult'),
    props: route => ({
      sessionId: route.params?.sessionId,
    }),
  },
  {
    path: '/online-consultation/:sessionId',
    name: 'online-consultation',
    component: () => import('pages/online-consultation'),
    redirect: '/online-consultation/information/:sessionId',
    children: [
      {
        path: '/online-consultation/information/:sessionId',
        name: 'online-consultation-information',
        component: () => import('pages/online-consultation/Information'),
        props: route => ({
          sessionId: route.params?.sessionId,
        }),
      },
      {
        path: '/online-consultation/call/:sessionId',
        name: 'online-consultation-call',
        component: () => import('pages/online-consultation/Call'),
        props: route => ({
          sessionId: route.params?.sessionId,
        }),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404'),
  },
];

export default routes;
