import lodashCloneDeep from 'lodash.clonedeep';

// reexport jwt decoder
export { decodeJWT } from './jwt';

/**
 * debounce a function
 *
 * @param {function} fn
 * @param {number} [timeout=200]
 * @param {Object} opts
 * @param {boolean} opts.immediate
 * @returns {function} debounced function
 */
export function debounce (fn, timeout, opts) {
  let timeoutid = null;
  return function (...args) {
    if (timeoutid) clearTimeout(timeout);
    timeoutid = setTimeout(() => {
      timeoutid = null;
      if (!opts?.immediate) fn.apply(this, args);
    }, timeout);
    if (opts?.immediate || !timeout) fn.apply(this, args);
  };
}

/**
 * check if an entity is a POJO
 *
 * @param {any} obj
 * @returns {boolean}
 */
export function isPlainObject (obj) {
  if (obj === null || typeof obj !== 'object') return false;
  return Object.getPrototypeOf(obj) === Object.prototype;
}

/**
 * deep-clone an object
 *
 * @param {Object.<string,any>} obj
 * @returns {Object.<string,any>}
 */
export const cloneDeep = lodashCloneDeep;

/**
 * transform values in an object
 *
 * @param {Object.<string,any>} obj
 * @param {(value: any, key: string) => any} mapperFn
 * @returns {Object.<string,any>}
 */
export function mapValues (obj, mapperFn) {
  if (!obj) return obj;
  return Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: mapperFn(obj[key], key) }), {});
}

/**
 * pick key/value pairs in an object
 *
 * @param {Object.<string,any>} obj
 * @param {(value: any, key: string) => any} mapperFn
 * @returns {Object.<string,any>}
 */
export function pickBy (obj, pickerFn) {
  if (!obj) return obj;
  return Object.keys(obj).reduce((acc, key) => ({ ...acc, ...pickerFn(obj[key], key) && { [key]: obj[key] } }), {});
}

/**
 * Returns a function that tries to call a given callback, but backs off
 * every time it is called. After a reset interval elapses where the function is
 * not called at all, the backoff is reset.
 *
 * The chosen backoff is exponential, with random jitter of up to 50%.
 *
 * @param {Function} fn Callback to try
 * @param {Object} [state]
 * @param {number} [state.tryIndex=0] 0-based counter for number of tries
 * @param {Object} [state.callbackTimeoutId] Return value of setTimeout
 * @param {Object} [state.resetTimeoutId] Return value of setTimeout
 * @param {Object} [opts]
 * @param {Object} [opts.backoffParams]
 * @param {number} [opts.backoffParams.coefficient=1000]
 * @param {number} [opts.backoffParams.base=2]
 * @param {number} [opts.maxBackoffDelay=60000]
 * @param {number} [opts.resetInterval=10000]
 * @return {Function} A function that calls the fn callback with backoff.
 */
export function backoff (fn, state, opts) {
  // enforce default state
  state = { tryIndex: 0, ...state };

  // define helper functions
  const clearState = () => {
    state.tryIndex = 0;
    if (state.resetTimeoutId) clearTimeout(state.resetTimeoutId);
  };
  const setResetTimer = interval => {
    // start a "reset" timer:
    // if resetInterval milliseconds elapse without the timer being cleared,
    // assume a success and reset state counters
    state.resetTimeoutId = setTimeout(clearState, interval);
  };

  // return backed off function, to call several times
  // which will in turn call fn, but only after an exponentially growing delay
  return (...args) => {
    // the function has been called; assume a failure
    // remove all timers
    if (state.callbackTimeoutId) clearTimeout(state.callbackTimeoutId);
    if (state.resetTimeoutId) clearTimeout(state.resetTimeoutId);

    const { backoffParams, maxBackoffDelay = 60000, resetInterval = 10000 } = opts || {};
    const tryFn = () => {
      fn(...args);
      setResetTimer(resetInterval);
    };

    // calculate delay before trying the function
    let delay = 0;
    if (state.tryIndex) {
      // case: this is a retry
      // delay should grow exponentially with tryIndex
      const { coefficient = 1000, base = 2 } = backoffParams || {};
      const expDelay = coefficient * (base ** (state.tryIndex - 1));
      const limitedDelay = Math.min(expDelay, maxBackoffDelay);
      // add random jitter, plus or minus 50%
      const sign = Math.random() < 0.5 ? -1 : 1;
      const jitterFactor = 1 + (sign * 0.5 * Math.random());
      delay = limitedDelay * jitterFactor;
    }

    // try calling the function after the delay
    state.tryIndex += 1;
    state.callbackTimeoutId = setTimeout(tryFn, delay);
  };
}

/**
 * Checks whether an error is a network error thrown by the browser. Makes use
 * of duck typing and string matching, due to the error being
 * implementation-specific by nature.
 *
 * @param {Error} error
 * @return {boolean}
 */
export function isBrowserNetworkError (error) {
  // check if error is indeed an Error
  if (!(error instanceof Error)) return false;

  // match error message to known network error messages
  switch (error.message) {
    case 'Failed to fetch': return true;
    case 'NetworkError when attempting to fetch resource.': return true;
    case 'Network request failed': return true;
    default: return false;
  }
}
