import isArray from 'lodash/isArray';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import mapValues from 'lodash/mapValues';
import every from 'lodash/every';
/**
 * Normalizes nested $populated from an object. Also normalizes objects under
 * array keys.
 *
 * @example
 * const result = {
 *   a: 1,
 *   b: 2,
 *   $populated: {
 *     c: {
 *       d: 3,
 *       $populated: {e: 4},
 *     },
 *     f: [{g: 5, $populated: {h: 6}}]
 *   }
 * };
 * const normalizedResult = normalizePopulated(result);
 * console.log(normalizedResult);
 * // {a: 1, b: 2, c: {d: 3, e: 4}, f: [{g: 5, h: 6}]}
 *
 * @param {any} val - Object or array of objects to normalize.
 * @return {any} Same object or array, but $populated contents spread into
 * parent.
 */
export const normalizePopulated = (val) => {
  if (isArray(val)) {
    return map(val, normalizePopulated);
  } else if (isObject(val)) {
    return {
      ...omit(val, '$populated'),
      ...mapValues(val.$populated, normalizePopulated),
    };
  } else {
    return val;
  }
};

export const isForOnboarding = (
  user,
  steps = [
    'dateOfBirth',
    'sex',
    'bloodType',
    'civilStatus',
    'insurance',
  ],
) => {
  if (!user?.onboarding?.pxp) return true;
  const onboardingStepsMapped = steps.map(step => user.onboarding.pxp[step]);
  if (!every(onboardingStepsMapped, Boolean)) return true;
  return false;
};
