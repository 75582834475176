export function setNotifications (state, data) {
  state.notifications = data || [];
}

export function setNotificationsCount (state, count) {
  state.notificationsCount = count || 0;
}

export function spliceNotifications (state, index) {
  state.notifications.splice(index, 1);
}
