// import { every } from 'lodash';
import sdk from '@mycure/sdk-js';

// function isForOnboarding (user) {
//   if (!user?.onboarding?.pxp) return true;
//   const onboardingStepsMapped = [
//     'dateOfBirth',
//     'sex',
//     'bloodType',
//     'civilStatus',
//     'insurance',
//   ].map(step => user.onboarding.pxp[step]);
//   if (!every(onboardingStepsMapped, Boolean)) return true;
//   return false;
// };

function isForOptDoctorOptIn (user) {
  return user?.isDoctor && !user?.apps?.includes('pxp');
}

export default async (to, from, next) => {
  try {
    const user = await sdk.service('auth').currentUser();
    const requiresAuth = to.matched.some(r => r.meta.requiresAuth);
    // const onboarding = to.matched.some(r => r.meta.onboarding);
    const optIn = to.matched.some(r => r.meta.optIn);
    if (to.name === 'signin' && user) return next({ name: 'dashboard' });
    if (requiresAuth) {
      // if no user go to signin
      if (!user) return next({ name: 'landing' });

      // doctor opt-in
      if (!optIn && isForOptDoctorOptIn(user)) return next({ name: 'doctor-opt-in' });
      if (optIn) return next();

      // otp
      // if (!user.isMobileNoVerified) {
      //   if (to.name !== 'otp') return next({ name: 'otp' });
      //   if (to.name === 'otp') return next();
      // } else {
      //   if (to.name === 'otp') return next({ name: 'dashboard' });
      // }

      // Email verification
      if (!user.isEmailVerified) {
        if (to.name !== 'verify-email') return next({ name: 'verify-email' });
        if (to.name === 'verify-email') return next();
      } else {
        if (to.name === 'verify-email') return next({ name: 'home' });
      }

      // onboarding
      // if (!onboarding && isForOnboarding(user)) return next({ name: 'onboarding' });
      // if (onboarding && isForOnboarding(user)) return next();

      // if has user go to dashboard
      // if (user) return next({ name: 'dashboard' });
    }
    next();
  } catch (e) {
    console.error(e);
    next({ name: 'landing' });
  }
};
