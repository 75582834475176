export default {
  path: '/pme-result',
  name: 'pme-result',
  component: () => import('layouts/Standard'),
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: 'preview/:id',
      name: 'pme-result-preview',
      component: () => import('pages/pme-records/PMEResultPreview'),
      props: route => ({
        id: route.params?.id,
      }),
      meta: {
        requiresAuth: true,
        backRoute: 'pme-records',
        showToolbar: true,
        toolbarTitle: 'PME Result Preview',
      },
    },
  ],
};
