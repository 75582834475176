import EventEmitter from 'eventemitter2';

/**
 * @typedef {import('./CRUDClientService').ServiceDocument} ServiceDocument
 * @typedef {import('./CRUDClientService').ClientServiceParams} ClientServiceParams
 * @typedef {import('./CRUDClientService').CreatePayloadItem} CreatePayloadItem
 * @typedef {import('./CRUDClientService').UpdatePayload} UpdatePayload
 * @typedef {import('./request-handler').RequestHandlerOpts} RequestHandlerOpts
 */

export class ClientService extends EventEmitter {
  /**
   * @param {string} name
   * @param {Object} opts
   * @param {string} [opts.id='id']
   */
  constructor (name, opts) {
    super();
    this.name = name;
    if (!this.name) throw new Error('Name required!');
    this.opts = Object.assign({ id: 'id' }, opts);
  }

  /**
   * setup a client service
   * @param {import('../client').Client} client
   * @param {string} path
   */
  setup (client, path) {
    this.client = client;
    this.path = path;
    this.opts = Object.assign({}, this.client.get(path), this.opts);
  }

  /**
   * execute http request
   * @param {string} method
   * @param {string} [id]
   * @param {CreatePayloadItem|CreatePayloadItem[]|UpdatePayload} [data]
   * @param {ClientServiceParams} [params]
   * @param {string} [service]
   * @param {RequestHandlerOpts} opts request handler override
   * @returns {Promise<ServiceDocument>}
   */
  async request (method, id, data, params, service = this.name, opts) {
    if (!this.client) {
      throw new Error('SDK not yet initialized');
    }
    return this.client.request(method, id, data, params, {
      ...opts,
      baseURI: [this.client.uri, service].join('/'),
    });
  }
}
