import { ClientService, ObservableUploadTask } from '../../utils';

/**
 * @typedef {import('../../utils/ObservableUploadTask.js').UploadFileOptions} UploadFileOptions
 */

export class StorageService extends ClientService {
  constructor () {
    super('files');
  }

  /**
   * upload file
   * @param {string} data
   * @param {UploadFileOptions} opts
   * @returns {ObservableUploadTask}
   */
  uploadFile (data, opts) {
    return new ObservableUploadTask(data, this.client, opts);
  }

  /**
   * upload image
   * @param {string} data
   * @param {UploadFileOptions} opts
   * @returns {ObservableUploadTask}
   */
  uploadImage (data, opts) {
    return this.uploadFile(data, opts);
  }
}
