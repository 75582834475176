<template>
  <router-view />
</template>

<script>
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Plugins } from '@capacitor/core';
import { setCssVar } from 'quasar';

const { StatusBar, PushNotifications } = Plugins;

export default {
  name: 'App',
  data () {
    return {
      notificationsWatcher: null,
    };
  },
  computed: {
    currentUser () {
      return this.$store.state.auth.currentUser;
    },
    isDashboard () {
      return this.$route.matched.some(r => r.meta.dashboard);
    },
  },
  watch: {
    $route (val) {
      if (!val.matched.some(r => r.meta.dashboard)) {
        clearInterval(this.notificationsWatcher);
      }
      if (val.matched.some(r => r.meta.dashboard) && this.currentUser) {
        this.initNotifications();
      }
    },
  },
  beforeUnmount () {
    clearInterval(this.notificationsWatcher);
  },
  created () {
    setCssVar('primary', '#0099cc');
  },
  async mounted () {
    defineCustomElements(window);

    // STATUS BAR
    if (this.$isNativeMobile) {
      StatusBar.setBackgroundColor({ color: 'white' });
      StatusBar.setStyle({ style: 'LIGHT' });
    }

    // REALTIME NOTIFICATIONS
    const user = await this.$sdk.service('auth').currentUser();
    if (user && this.isDashboard) this.initNotifications();

    // PUSH NOTIFICATIONS
    if (this.$isNativeMobile && user) {
      const result = await PushNotifications.requestPermission();
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', this.onRegistration);

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', this.onRegistrationError);

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', this.onPushNotificationreceived);

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed', this.onPushNotificationActionPerformed);
    }
  },
  methods: {
    async initNotifications () {
      clearInterval(this.notificationsWatcher);
      if (!this.currentUser) return;
      const query = {
        seenBy: { $ne: this.currentUser.uid },
        'viewers.uid': this.currentUser.uid,
        $limit: 0,
        $total: true,
      };
      this.notificationsWatcher = setInterval(async () => {
        const { total } = await this.$sdk
          .service('notifications')
          .find(query);
        this.$store.commit('notifications/setNotificationsCount', total);
      }, 5000);
    },
    async onRegistration (token) {
      await this.$sdk.service('accounts').update(this.currentUser.uid, {
        $addToSet: {
          deviceTokens: token.value,
        },
      });
    },
    onRegistrationError (error) {
      console.error(error);
      this.$showSnack({
        message: 'Push notifications registration error' + JSON.stringify(error),
      });
    },
    onPushNotificationreceived (notification) {
      this.$showSnack({
        message: 'You have a new notification',
      });
    },
    onPushNotificationActionPerformed (notification) {
      const { code, appointment } = notification?.notification?.data || {};
      const appointmentsNotificationCodes = [
        'appointment-rescheduled-by-doctor',
        'appointment-confirmed-by-doctor',
        'appointment-day',
      ];
      if (appointmentsNotificationCodes.includes(code)) {
        this.$router.push({ name: 'appointment', params: { id: appointment } });
        return;
      }
      this.$router.push({ name: 'dashboard' });
    },
  },
};
</script>

<style>
body {
  /* color: slategrey; */
  color: #101828;
}
</style>
